import React from "react";
import Slider from "react-slick";

// Custom arrow components
const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow-home-first`}
      onClick={onClick}
    >
      &#10095; {/* Unicode for right arrow, replace with any icon or image */}
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow-home-first`}
      onClick={onClick}
    >
      &#10094; {/* Unicode for left arrow, replace with any icon or image */}
    </div>
  );
};

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      <Slider {...settings}>
        <div className="amazing-deals">
          <div className="row">
            <div className="col-md-6">
              <h1 className="pt-5">
                Amazing Deals On <span style={{ color: "#EC1C25" }}>Vaillant</span> Boilers
              </h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text.
              </p>
              <button>Contact Us</button>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
        <div>
          <div className="amazing-deals pb-5">
            <div className="row">
              <div className="col-md-6">
                <h1 className="pt-5">
                  Amazing Deals On <span style={{ color: "#EC1C25" }}>Vaillant</span> Boilers
                </h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text.
                </p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
