import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import 'aos/dist/aos.css';
import SocialLink from './SocialLink';
import Img from '../../Images/trustpilot (1).png';
import Img2 from '../../Images/trustpilot (2).png';

function Footer() {
  return (
    <footer>
      <div className="footer-main">
        <div className="container-fluid m-0 p-0">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer_logo">
                  <img src={logo} alt="Company Logo" />
                </div>
                
                <p className="footer-lorem">
                No 5, Trafford Road, Reading, RG1 8JP
                </p>
                <p>0118 958 8882 online@getplumb.co.uk </p>
                <p>Monday - Friday: 8am - 6:00pm <br /> Saturday: 8:00 am - 5:00 pm <br />Sunday: Closed</p>
                <p>Company Registration No: <span style={{color:"red"}}> 13074653</span> <br /> VAT Registration No:  <span style={{color:"red"}}>369716745</span></p>
              </div>

              <div className="col-lg-3 col-md-6 about_ft">
                <h5 className="about_h5">Useful Links</h5>
             <p>About Us</p>
             <p>Brands</p>
             <p>Upcoming Events</p>
             <p>Latest News</p>
             <p>Customer Testimonials</p>
              </div>

              <div className="col-lg-3 col-md-6 about_ft">
                <h5 className="about_h5">Useful Links</h5>
                <p>Contact Us</p>
                <p>Delivery Information</p>
                <p>Knowledge Base</p>
                <p>Privacy Policy</p>
                <p>Return Policy</p>
                 
              </div>

              <div className="col-lg-3 col-md-6 about_ft">
                <h5 className="about_h5">Social Links</h5>
              <SocialLink />
                <div className="image-logo">
                <img src={Img} alt="" />
                <img src={Img2} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid getplumb-footer ">
          
            <div className="container">
            <p className="copy_right p-2">©2024 GetPlumb <span>Design by Sharp-aX Computer Systems Ltd</span></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
