import React from 'react'
import Header from '../Header/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
// import SideFilter from './SideFilter';
// import CardSlider from '../Home/CardSlider';
// import SelectCetagory from './SelectCetagory';
import ZoomSlider from './ZoomSlider';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Cart from './Cart';
import Accordion from 'react-bootstrap/Accordion';
import NavTabs from './NavTabs';
import FeaturedProductSecond from '../Home/FeaturedProductSecond';
import Footer from '../Footer/Index';


function index() {
  return (
    <div style={{background:"#F4F4F4"}}>
        <div className="product-details pb-4">
            <Header />
            <div className="container pb-5">
            <h1 className='shop-catergory-h1 pt-5'>Product Details</h1>
            <p className='shop-catergory-p'>Home <FontAwesomeIcon icon={faAngleRight} style={{fontSize:"12px"}} /> Shop <FontAwesomeIcon icon={faAngleRight} style={{fontSize:"12px"}} />  <span style={{color:"red"}}>Open vent Boiler</span></p>
            </div>
        </div>
        <div className="container  pb-5">
            <div className="row  mt-5">
                <div className="col-md-6 pb-5 p-3">
                <ZoomSlider />
                </div>
                <div className="col-md-6 products-details products-details-new p-5 pt-3">
                  <h2 className='products-details-heading'>Open Vent Boilers (Heat Only)</h2>
                  <h2 className='products-details-price pt-2'>$20.00 <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></h2>
                  <p className='products-details-p pt-2'>These high-quality, Kitemarked JTM Copper Pipes have been manufactured to British Copper Pipe Standards and come available in a wide range of sizes to suit a variety of plumbing projects. Not only sold individually, but you can also purchase pipe bundles of 10 and 20, perfect ...  <span style={{color:"red"}}>Read more</span></p>

                  <Cart />
                  <h5>View full product details</h5>
                  <p className='products-details-p '>These high-quality, Kitemarked JTM Copper Pipes have been manufactured to British Copper Pipe Standards and come available in a wide range of sizes to suit a variety of plumbing projects.</p>
                  
                  <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>View full product details</Accordion.Header>
        <Accordion.Body>
        These high-quality, Kitemarked JTM Copper Pipes have been manufactured to British Copper Pipe Standards and come available in a wide range of sizes to suit a variety of plumbing projects.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>View question about the product</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                </div>
               
            </div>
        </div>
        <div className="container">
          <NavTabs />
        </div>
        <div className="container-fluid pb-5 ">
            <div className="featured-this-week-second">
              <div className="container ">
                <h2 className="mb-5">Related Product</h2>
                <FeaturedProductSecond />
              </div>
            </div>
          </div>
          <Footer />

    </div>
  )
}

export default index