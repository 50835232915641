import React from 'react'
import Card1 from '../../Images/Group (1).svg';
import Card2 from '../../Images/Group (2).svg';
import Card3 from '../../Images/Group (3).svg';
import Card4 from '../../Images/Group (4).svg';

function PlumbingCategories() {
  return (
    <div>
        <div className="row">
            <div className="col-lg-3 col-md-6 p-3">
                <div className="bathroom-accessories-cards">
                <img src={Card1} alt="" className='mx-auto pt-5' />
                <h5 className='pt-3 '>MDPE PIPES & FITTINGS</h5>
                <p>On orders before 2PM</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 p-3">
                <div className="bathroom-accessories-cards">
                <img src={Card2} alt="" className='mx-auto pt-5' style={{width:"30px"}}/>
                <h5 className='pt-3 '>MDPE PIPES & FITTINGS</h5>
                <p>In our stock range</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 p-3">
                <div className="bathroom-accessories-cards">
                <img src={Card3} alt="" className='mx-auto pt-5' />
                <h5 className='pt-3 '>MDPE PIPES & FITTINGS</h5>
                <p>In our stock range</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 p-3">
                <div className="bathroom-accessories-cards">
                <img src={Card4} alt="" className='mx-auto pt-5' />
                <h5 className='pt-3 '>MDPE PIPES & FITTINGS</h5>
                <p>On 100's of products</p>
                </div>
            </div>
          
        </div>
    </div>
  )
}

export default PlumbingCategories