import React from 'react'
import image from '../../Images/image 4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";


function FeaturedProduct() {
  return (
    <div >
     <div className="item_owll mt-5">
    <img src={image} alt="" />
    <h5 className='pt-4 px-2'>Flexigas Pipes & Fittings</h5>
    <h4 className='px-2'><b>$20.00</b></h4>
    <div className='px-2 pb-3'>
        
            <Link to="/product-details">
                <button className="get_a_quote_btn1 mt-2" type="submit">Quick view <FontAwesomeIcon icon={faArrowRight} style={{float:"right"}} className='mt-1'/></button>
            </Link>
      
      
    </div>
</div>

                       
    </div>
  )
}

export default FeaturedProduct