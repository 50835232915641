import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home/Home";
import Listing from "./components/Listing";
import MyProfile from "./components/MyProfile";
import { Routes, Route } from "react-router-dom";
import Footer from './components/Footer/Index';
import ShopCatergory from './components/ShopCatergory/index'
import ProductDetails from './components/ProductDetails/index';
import Account from './components/Account/Index';

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/listing" Component={Listing} />
        <Route path="/myprofile" Component={MyProfile} />
        <Route path="/footer" Component={Footer} />
        <Route path="/shop-catergory" Component={ShopCatergory} />
        <Route path="/product-details" Component={ProductDetails} />
        <Route path="/account" Component={Account} />
      </Routes>
    </>
  );
}

export default AppRoutes;
