import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccountInformation from './AccountInformation';
import ContactInformation from './ContactInformation';
import DeliveryAddresses from './DeliveryAddresses';
import MyOrders from './MyOrders';
import MyQuotes from './MyQuotes';


// import YourImage from '../../Images/seting.png';
import profile2 from '../../Images/Group 1948757171.png';
import profile3 from '../../Images/Group 1948757172.png';
import profile4 from '../../Images/Group 1948757173.png';
import profile5 from '../../Images/Group 1948757174.png';
import profile6 from '../../Images/Group 1948757175.png';
import profile7 from '../../Images/Group 1948757176.png';
import profile8 from '../../Images/Group 1948757177.png';
import profile9 from '../../Images/Group 1948757178.png';
import profile10 from '../../Images/Group 1948757179.png';
// import profile12 from '../../Images/seting (1).png';
// import profile10 from '../../Images/seting-10.png';
// import profile11 from '../../Images/seting-11.png';

import { AccountCircle } from '@mui/icons-material'; // Importing AccountCircle icon from Material-UI icons

function CustomImage() {
  return <img src={profile2} alt="Your Alt Text" className="me-2 image-inline" /> ;
}
function CustomImage2() {
  return <img src={profile3} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage3() {
  return <img src={profile4} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage4() {
  return <img src={profile5} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage5() {
  return <img src={profile6} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage6() {
  return <img src={profile7} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage7() {
  return <img src={profile8} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage8() {
  return <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />;
}
function CustomImage9() {
    return <img src={profile10} alt="Your Alt Text" className="me-2 image-inline" />;
  }
  
// function CustomImage9() {
//   return <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />;
// }
// function CustomImage10() {
//   return <img src={profile10} alt="Your Alt Text" className="me-2 image-inline" />;
// }
// function CustomImage11() {
//   return <img src={profile11} alt="Your Alt Text" className="me-2 image-inline" />;
// }
// function CustomImage12() {
//   return <img src={profile12} alt="Your Alt Text" className="me-2 image-inline" />;
// }


const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const VerticalTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="container pt-5">
      <div className="row mt-2 mb-5">
        <div className="col-lg-3 side-nav pt-4">
          <div className="inner-shadow" style={{overflow:"hidden"}}>
           
            
          

            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 0, borderColor: "divider" }}
            >
              <Tab label="Account Information" icon={<CustomImage />} {...a11yProps(0)} className="tabs-tab" />
              <Tab label="Contact Information" icon={<CustomImage2 />} {...a11yProps(1)} className="tabs-tab" />
              <Tab label="Billing / Delivery Addresses" icon={<CustomImage3 />} {...a11yProps(1)} className="tabs-tab" />
              <Tab label="My Orders" icon={<CustomImage4 />} {...a11yProps(2)} className="tabs-tab" />
              <Tab label="My Quotes" icon={<CustomImage5 />} {...a11yProps(3)} className="tabs-tab" />
              <Tab label="Favorite Products" icon={<CustomImage6 />} {...a11yProps(4)} className="tabs-tab" />
              <Tab label="Help Center" icon={<CustomImage7 />} {...a11yProps(5)} className="tabs-tab" />
              <Tab label="Contact us" icon={<CustomImage8 />} {...a11yProps(6)} className="tabs-tab" />
              <Tab label="Logout" icon={<CustomImage9 />} {...a11yProps(7)} className="tabs-tab" />
              
              {/* <Logout /> */}
            </Tabs>
          </div>
        </div>
        <div className="col-lg-9 p-0">
          <TabPanel value={value} index={0} sx={{ Padding: "0px" }} className="p-0 m-0" >
            <AccountInformation />
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ Padding: "0px" }}>
            <ContactInformation />
          </TabPanel>
          <TabPanel value={value} index={2} sx={{ Padding: "0px" }}>
            <DeliveryAddresses />
          </TabPanel>
          <TabPanel value={value} index={3} sx={{ Padding: "0px" }}>
            <MyOrders />
          </TabPanel>
          <TabPanel value={value} index={4} sx={{ Padding: "0px" }}>
            <MyQuotes />
          </TabPanel>
       
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
