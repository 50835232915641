import React from 'react'

function MyQuotes() {
  return (
    <div>
      <div className="inner-shadow p-4">
        <h3 className="overview-heading">Account Information <span className='overview-heading-span'>Update Password?</span></h3>
        <div className="">
          <span>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque, incidunt dolorem amet sunt maxime culpa dolores quisquam minus voluptates, doloribus, id placeat nisi? Eaque doloribus aut eos corrupti quos quo?</p>
          </span>
        </div>
      </div>
    </div>
  )
}

export default MyQuotes