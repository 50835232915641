import React, {useState} from 'react'
import Benefits1 from '../../Images/Benefits (1).png';
import Benefits2 from '../../Images/Benefits (2).png';
import Benefits3 from '../../Images/Benefits (3).png';
import Benefits4 from '../../Images/Benefits (4).png';
import Benefits5 from '../../Images/Benefits (5).png';
import Benefits6 from '../../Images/Benefits (6).png';
import Benefits7 from '../../Images/Benefits (7).png';
import Benefits8 from '../../Images/Benefits (8).png';

function Benefitsforyou() {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
      setShowMore(!showMore);
    };
  return (
    <div>
         <div className="container pb-5">
          <h1 className='benefits-for-you-h1'>Benefits for you   <button 
            className='benefits-for-you-button mt-4' 
            onClick={toggleShowMore}
          >
            {showMore ? 'Less all' : 'See all'}
          </button></h1>
          <div className="row">
            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits1} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits2} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits3} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits4} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits5} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits6} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits7} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits8} alt="" />
              </div>
            </div>
            
          </div>
          {showMore && (

          <div className="row" >
            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits1} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits2} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits3} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits4} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits5} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits6} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits7} alt="" />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-3">
              <div className="benifits-for">
                <img src={Benefits8} alt="" />
              </div>
            </div>
            
          </div>
            )}
          
        </div>
    </div>
  )
}

export default Benefitsforyou