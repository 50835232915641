import React from 'react'

function AccountInformation() {
  return (
    <div className='p-0 m-0'>
       <div className="inner-shadow p-4">
        <h3 className="overview-heading">Account Information <span className='overview-heading-span'>Update Password?</span></h3>
        <p className='pt-4'><b>Account Name:</b> John Deo</p>
        <p><b className='overview-heading-b'>Account Code:</b> WEB-2932</p>
        <p><b className='overview-heading-b'>Currency:</b> $1223.00</p>
        <p><b className='overview-heading-b'>Email Address:</b> shubham@gmail.com</p>
        <p><b className='overview-heading-b'>Address:</b> #78 House Street Cargo Bar</p>
        <p><b className='overview-heading-b'>Country:</b> India</p>
      </div>
    </div>
  )
}

export default AccountInformation