import React from 'react';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';

function CartIconWithBadge({ itemCount }) {
  return (
    <IconButton color="inherit">
      <Badge badgeContent={2} color="info">
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
}

export default CartIconWithBadge;
