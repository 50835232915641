import React from 'react'
import Header from '../Header/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import NavTabs from './NavTabs';

function Index() {
  return (
    <div>
      
        <div className="product-details">
            <Header />
            <div className="container pb-5">
            <h1 className='shop-catergory-h1 pt-5'>Account</h1>
            <p className='shop-catergory-p'>Home <FontAwesomeIcon icon={faAngleRight} style={{fontSize:"12px"}} />  <span style={{color:"red"}}>Account</span></p>
            </div>
        </div>
        <NavTabs />
        
    </div>
  )
}

export default Index