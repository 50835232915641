import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider() {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box >
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        sx={{
          color: 'red', // Change the color of the slider to red
          '& .MuiSlider-thumb': {
            color: 'red',
            // height: 7,  // Change the color of the thumb to red
          },
          '& .MuiSlider-track': {
            color: 'red',
            height: 7,  // Change the color of the track to red
          },
          '& .MuiSlider-rail': {
            color: '#EAEAEA', 
            height: 7,  // Change the color of the rail to red
          },
        }}
      />
    </Box>
  );
}
