import React from 'react'
import Header from '../Header/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import SideFilter from './SideFilter';
import CardSlider from '../Home/CardSlider';
import SelectCetagory from './SelectCetagory';
import Footer from '../Footer/Index';


function index() {
  return (
    <div style={{background:"#F4F4F4"}}>
        <div className="shop-catergory pb-4">
            <Header />
            <div className="container pb-5">
            <h1 className='shop-catergory-h1 pt-5'>Shop</h1>
            <p className='shop-catergory-p'>Home <FontAwesomeIcon icon={faAngleRight} style={{fontSize:"12px"}} />  <span style={{color:"red"}}>Shop</span></p>
            </div>
        </div>
        <div className="container  pb-5">
            <div className="row">
                <div className="col-md-4 mt-5">
                <SideFilter />
                </div>
                <div className="col-md-8 mt-5">
                <SelectCetagory />
                    <h4 className='filter-listing'>Showing 1–6 of 8 results</h4>
                    <div className="row mt-4">

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>

                        <div className="col-lg-4 col-md-6 m-0 ">
                            <CardSlider />
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default index