import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TextField, InputAdornment } from '@mui/material';
import Accordion from 'react-bootstrap/Accordion';
import Range from './Range';

function SideFilter() {
  return (

        <div className="filter p-3 pb-4">
            <label htmlFor="" className='pb-2'>Search</label>
            <br />
            <TextField
      variant="outlined"
      placeholder="Search..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
      className='filter-search'
    />

<label htmlFor="" className='pb-2 pt-4'>Categories</label>
<Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Heating</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Pumps</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>

    

    <label htmlFor="" className='pb-2 pt-4'>Tags</label>
    <br />
    <div className="filter-tags">
        <div>Drains Cleaning</div>
        <div>Installation</div>
        <div>Repair</div>
        <div>Services</div>
    </div>
    <br />

    <label htmlFor="" className='pb-2 pt-4'>Price</label>
    <br />
    <Range />

    <button className='filter-button mt-4'>Filter</button>
    


    
        </div>
 
  )
}

export default SideFilter