import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function AccessibleTabs2() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Tabs where each tab needs to be selected manually"
        sx={{
          "& .MuiTab-root": {
            color: "#EC1C25 ", // Inactive tab text color
            backgroundColor: "lightgray",
            margin: "0px 5px",
            padding:'10px 30px',
            border: "1px solid #EC1C25",
            background: "white", // Inactive tab background color
            borderRadius: "5px 5px 0px 0px",
            "&:hover": {
              backgroundColor: "#d3d3d3", // Hover effect for inactive tabs
            },
          },
          "& .Mui-selected": {
            color: "white", // Active tab text color
            backgroundColor: "#EC1C25", // Active tab background color
            // border:'1px soild #EC1C25',
          },
        }}
      >
        <Tab label="Delivery" {...a11yProps(0)} />
        <Tab label="Return" {...a11yProps(1)} />
      </Tabs>
      <hr className="m-0 p-0" />
      <TabPanel value={value} index={0}>
        <Typography variant="h6">Delivery Information</Typography>
        <Typography paragraph className="pt-2">
          Flexibility in deliveries is the most asked-for feature when it comes
          to trade customers. So bearing that in mind, you can find the delivery
          rules as follows:
        </Typography>
        <Typography paragraph>
          1. FREE Own Van Local Delivery within a 40-mile
          radius from our warehouse at RG1 8JP and with a minimum order value of
          £30. FYI, that roughly covers the whole of Berkshire, Middlesex, and
          parts of South Bucks, Hertfordshire, Surrey, Oxfordshire, and Central
          London.
          <br />
          <em>Exclusions:</em> Water Softener Salt is only delivered free within
          a 25-mile radius from our Warehouse.
        </Typography>
        <Typography paragraph>
          2. For small orders less than 25 kgs or less than 2.1 meters in
          length, we charge for a{" "}
          Standard Next Day Parcel Service - £6.95 + VAT.
        </Typography>
        <Typography paragraph>
          3. For everything else, there is a{" "}
          Big and Bulky – 2-3 days Pallet Service for £50 +
          VAT.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Content for Return
      </TabPanel>
    </Box>
  );
}
