import React from 'react'
import Order from '../Home/CardSlider';

function MyOrders() {
  return (
    <div>
      <div className="inner-shadow p-4">
        <h3 className="overview-heading">My Orders <span className='overview-heading-span'>Update Password?</span></h3>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <Order />
          </div>
          <div className="col-lg-4 col-md-6">
          <Order />
          </div>
          <div className="col-lg-4 col-md-6">
          <Order />
          </div>
        </div>
      
      </div>
    </div>
  )
}

export default MyOrders