import React from "react";
import Slider from "react-slick";
import CardSlider from './CardSlider';

// Custom arrow components
const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow-home-second`}
      onClick={onClick}
    >
      &#10095; {/* Unicode for right arrow, replace with any icon or image */}
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow-home-second`}
      onClick={onClick}
    >
      &#10094; {/* Unicode for left arrow, replace with any icon or image */}
    </div>
  );
};

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 768, // Screen width of 768px or less
        settings: {
          slidesToShow: 1, // Show only 1 slide
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000, // Screen width of 768px or less
        settings: {
          slidesToShow: 3, // Show only 1 slide
          slidesToScroll: 3,
        },
      },
      // You can add more breakpoints if needed
    ],
  };

  return (
    <>
   
      <Slider {...settings}>
        <div className="px-2 mt-3">
        <CardSlider />
        </div>

        <div className="px-2 mt-3">
        <CardSlider />
        </div>

        <div className="px-2 mt-3">
        <CardSlider />
        </div>

        <div className="px-2 mt-3">
        <CardSlider />
        </div>

        <div className="px-2 mt-3">
        <CardSlider />
        </div>

        <div className="px-2 mt-3">
        <CardSlider />
        </div>

        <div className="px-2 mt-3">
        <CardSlider />
        </div>

     

      </Slider>
    </>
  );
}
