import React from 'react'
import Form from 'react-bootstrap/Form';

function SelectCetagory() {
  return (
    <div>
         <Form.Select aria-label="Default select example" className='default-sorting'>
      <option>Default Sorting</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
    </div>
  )
}

export default SelectCetagory