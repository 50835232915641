import React, { Component } from "react";
import Slider from "react-slick";
// import { baseUrl } from "./config";
// import dfd from '../../Images';
import img1 from "../../Images/products1.png"
import img2 from "../../Images/products2.png"
import img3 from "../../Images/products3.png"
import img4 from "../../Images/products4.png"


function CustomPaging() {
  const settings = {
    customPaging: function(i) {
        console.log("img", i)
        let img = i == 0 
        ? img1 
        : i == 1 
        ? img2 
        : i == 2 
        ? img3 : img4;
      return (
        <a>
          <div className="custom-dots-image-background">
          <img src={img} className="custom-dots-image" />
          </div>
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="owl-custom-image-zoom">
          <img src={img1} />
        </div>
        <div className="owl-custom-image-zoom">
          <img src={img2} />
        </div>
        <div className="owl-custom-image-zoom">
          <img src={img3} />
        </div>
        <div className="owl-custom-image-zoom">
          <img src={img4} />
        </div>
      </Slider>
    </div>
  );
}

export default CustomPaging;
