import React from 'react'
import Card1 from '../../Images/image 16.png';
import Card2 from '../../Images/image 17.png';
import Card3 from '../../Images/image 18.png';
import Card4 from '../../Images/image 19.png';

function PlumbingCategories() {
  return (
    <div>
        <div className="row">
            <div className="col-lg-3 col-md-6 p-3">
                <div className="plumbing-categories-cards">
                <img src={Card1} alt="" className='mx-auto pt-3' />
                <h5 className='pt-3 pb-3'>MDPE PIPES & FITTINGS</h5>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 p-3">
                <div className="plumbing-categories-cards">
                <img src={Card2} alt="" className='mx-auto pt-3' />
                <h5 className='pt-3 pb-3'>MDPE PIPES & FITTINGS</h5>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 p-3">
                <div className="plumbing-categories-cards">
                <img src={Card3} alt="" className='mx-auto pt-3' />
                <h5 className='pt-3 pb-3'>MDPE PIPES & FITTINGS</h5>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 p-3">
                <div className="plumbing-categories-cards">
                <img src={Card4} alt="" className='mx-auto pt-3' />
                <h5 className='pt-3 pb-3'>MDPE PIPES & FITTINGS</h5>
                </div>
            </div>
          
        </div>
    </div>
  )
}

export default PlumbingCategories