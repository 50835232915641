import React from 'react'
import Form from "react-bootstrap/Form";

function AccountInformation() {
  return (
    <div className='p-0 m-0'>
       <div className="inner-shadow p-4">
        <h3 className="overview-heading">Contact Information <span className='overview-heading-span'>Update Password?</span></h3>
        <div className="row">
          <div className="col-md-6">
            <Form.Label htmlFor="inputPassword5" className="form-label mt-4">
            First Name
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="form-input mt-1"
              />
          </div>
          <div className="col-md-6">
            <Form.Label htmlFor="inputPassword5" className="form-label mt-4">
            Last Name
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="form-input mt-1"
              />
          </div>
          <div className="col-md-6">
            <Form.Label htmlFor="inputPassword5" className="form-label mt-4">
            Email Address
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="form-input mt-1"
              />
          </div>
          <div className="col-md-6">
            <Form.Label htmlFor="inputPassword5" className="form-label mt-4">
            Phone Number
              </Form.Label>
              <Form.Control
                type="number"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="form-input mt-1"
              />
          </div>
          <div className="col-md-6">
            <Form.Label htmlFor="inputPassword5" className="form-label mt-4">
            <input type="checkbox" name="" id="" /> Do you want to receive latest news, tips, offers, and more.
              </Form.Label>
              
          </div>
        </div>
        <button className='update-account mt-4'>Update Account</button>
                
      </div>
    </div>
  )
}

export default AccountInformation