import logo from './logo.svg';
// src/index.js or src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './App.css';
import Routes from './AppRoutes';

function App() {
  return (
    <div >
    <Routes />
    </div>
  );
}

export default App;
