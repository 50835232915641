import React from "react";

const Listing = () => {
  return (
    <>
      <h1>Listing Component</h1>
    </>
  );
};

export default Listing;
