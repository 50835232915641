import React from "react";
import Form from "react-bootstrap/Form";

function AccountInformation() {
  return (
    <div className="p-0 m-0">
      <div className="inner-shadow p-4">
        <h3 className="overview-heading">
          Billing / Delivery Addresses{" "}
          <span className="overview-heading-span">Update Password?</span>
        </h3>
        <div className="row">
          <div className="col-md-6 mt-4">
            <div className="belling-cart">
              <p>
                <b>Billing Address</b>{" "}
                <span className="overview-heading-span">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    className="me-1"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M15.2711 7.27365C15.0782 7.27365 14.8933 7.35027 14.7569 7.48664C14.6205 7.62302 14.5439 7.80798 14.5439 8.00085V12.364C14.5439 12.9426 14.314 13.4975 13.9049 13.9066C13.4958 14.3158 12.9409 14.5456 12.3623 14.5456H3.63597C3.05738 14.5456 2.50249 14.3158 2.09336 13.9066C1.68423 13.4975 1.45439 12.9426 1.45439 12.364V3.63768C1.45439 3.05909 1.68423 2.5042 2.09336 2.09507C2.50249 1.68594 3.05738 1.4561 3.63597 1.4561H7.99914C8.192 1.4561 8.37697 1.37948 8.51334 1.24311C8.64972 1.10673 8.72633 0.921768 8.72633 0.728904C8.72633 0.53604 8.64972 0.351075 8.51334 0.214699C8.37697 0.0783239 8.192 0.00170898 7.99914 0.00170898H3.63597C3.15849 0.00170898 2.68568 0.0957562 2.24455 0.278481C1.80341 0.461206 1.40258 0.729029 1.06495 1.06666C0.383075 1.74854 0 2.67336 0 3.63768V12.364C0 12.8415 0.0940472 13.3143 0.276772 13.7554C0.459497 14.1966 0.72732 14.5974 1.06495 14.935C1.74683 15.6169 2.67165 16 3.63597 16H12.3623C12.8398 16 13.3126 15.9059 13.7537 15.7232C14.1949 15.5405 14.5957 15.2727 14.9333 14.935C15.271 14.5974 15.5388 14.1966 15.7215 13.7554C15.9042 13.3143 15.9983 12.8415 15.9983 12.364V8.00085C15.9983 7.80798 15.9217 7.62302 15.7853 7.48664C15.6489 7.35027 15.4639 7.27365 15.2711 7.27365Z"
                      fill="#EC1C25"
                    />
                    <path
                      d="M5.0031 6.88098C4.79959 7.08423 4.63829 7.32572 4.52846 7.59154C4.41864 7.85737 4.36246 8.14229 4.36317 8.42991V9.45525C4.36317 10.0338 4.59301 10.5887 5.00214 10.9979C5.41126 11.407 5.96616 11.6368 6.54475 11.6368H7.57009C7.85771 11.6375 8.14263 11.5814 8.40846 11.4715C8.67428 11.3617 8.91577 11.2004 9.11902 10.9969L15.3583 4.75757C15.5617 4.5549 15.7231 4.31406 15.8332 4.04889C15.9433 3.78371 16 3.49941 16 3.21228C16 2.92516 15.9433 2.64085 15.8332 2.37568C15.7231 2.1105 15.5617 1.86967 15.3583 1.667L14.333 0.641652C14.1303 0.43827 13.8895 0.276898 13.6243 0.166789C13.3591 0.0566796 13.0748 0 12.7877 0C12.5006 0 12.2163 0.0566796 11.9511 0.166789C11.6859 0.276898 11.4451 0.43827 11.2424 0.641652L5.0031 6.88098ZM13.3004 1.667L14.333 2.69961C14.4684 2.83586 14.5445 3.02017 14.5445 3.21228C14.5445 3.4044 14.4684 3.58871 14.333 3.72496L13.7076 4.36489L11.6351 2.29238L12.2605 1.667C12.3282 1.59773 12.4091 1.5427 12.4984 1.50513C12.5877 1.46756 12.6836 1.4482 12.7804 1.4482C12.8773 1.4482 12.9732 1.46756 13.0625 1.50513C13.1518 1.5427 13.2327 1.59773 13.3004 1.667ZM5.81755 8.42991C5.817 8.3342 5.83535 8.23933 5.87153 8.15073C5.90772 8.06213 5.96104 7.98155 6.02844 7.9136L10.617 3.325L12.675 5.38296L8.0864 9.97156C8.01845 10.039 7.93787 10.0923 7.84927 10.1285C7.76067 10.1647 7.6658 10.183 7.57009 10.1824H6.54475C6.35189 10.1824 6.16692 10.1058 6.03055 9.96945C5.89417 9.83308 5.81755 9.64811 5.81755 9.45525V8.42991Z"
                      fill="#EC1C25"
                    />
                  </svg>
                  Edit Address
                </span>
              </p>
              <p>#78 House Street Cargo Bar</p>
              <p>Chandigarh</p>
              <p>India</p>
              <p>134203</p>
              <p>Angola</p>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="belling-cart">
              <p>
                <b>Billing Address</b>{" "}
                <span className="overview-heading-span">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    className="me-1"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M15.2711 7.27365C15.0782 7.27365 14.8933 7.35027 14.7569 7.48664C14.6205 7.62302 14.5439 7.80798 14.5439 8.00085V12.364C14.5439 12.9426 14.314 13.4975 13.9049 13.9066C13.4958 14.3158 12.9409 14.5456 12.3623 14.5456H3.63597C3.05738 14.5456 2.50249 14.3158 2.09336 13.9066C1.68423 13.4975 1.45439 12.9426 1.45439 12.364V3.63768C1.45439 3.05909 1.68423 2.5042 2.09336 2.09507C2.50249 1.68594 3.05738 1.4561 3.63597 1.4561H7.99914C8.192 1.4561 8.37697 1.37948 8.51334 1.24311C8.64972 1.10673 8.72633 0.921768 8.72633 0.728904C8.72633 0.53604 8.64972 0.351075 8.51334 0.214699C8.37697 0.0783239 8.192 0.00170898 7.99914 0.00170898H3.63597C3.15849 0.00170898 2.68568 0.0957562 2.24455 0.278481C1.80341 0.461206 1.40258 0.729029 1.06495 1.06666C0.383075 1.74854 0 2.67336 0 3.63768V12.364C0 12.8415 0.0940472 13.3143 0.276772 13.7554C0.459497 14.1966 0.72732 14.5974 1.06495 14.935C1.74683 15.6169 2.67165 16 3.63597 16H12.3623C12.8398 16 13.3126 15.9059 13.7537 15.7232C14.1949 15.5405 14.5957 15.2727 14.9333 14.935C15.271 14.5974 15.5388 14.1966 15.7215 13.7554C15.9042 13.3143 15.9983 12.8415 15.9983 12.364V8.00085C15.9983 7.80798 15.9217 7.62302 15.7853 7.48664C15.6489 7.35027 15.4639 7.27365 15.2711 7.27365Z"
                      fill="#EC1C25"
                    />
                    <path
                      d="M5.0031 6.88098C4.79959 7.08423 4.63829 7.32572 4.52846 7.59154C4.41864 7.85737 4.36246 8.14229 4.36317 8.42991V9.45525C4.36317 10.0338 4.59301 10.5887 5.00214 10.9979C5.41126 11.407 5.96616 11.6368 6.54475 11.6368H7.57009C7.85771 11.6375 8.14263 11.5814 8.40846 11.4715C8.67428 11.3617 8.91577 11.2004 9.11902 10.9969L15.3583 4.75757C15.5617 4.5549 15.7231 4.31406 15.8332 4.04889C15.9433 3.78371 16 3.49941 16 3.21228C16 2.92516 15.9433 2.64085 15.8332 2.37568C15.7231 2.1105 15.5617 1.86967 15.3583 1.667L14.333 0.641652C14.1303 0.43827 13.8895 0.276898 13.6243 0.166789C13.3591 0.0566796 13.0748 0 12.7877 0C12.5006 0 12.2163 0.0566796 11.9511 0.166789C11.6859 0.276898 11.4451 0.43827 11.2424 0.641652L5.0031 6.88098ZM13.3004 1.667L14.333 2.69961C14.4684 2.83586 14.5445 3.02017 14.5445 3.21228C14.5445 3.4044 14.4684 3.58871 14.333 3.72496L13.7076 4.36489L11.6351 2.29238L12.2605 1.667C12.3282 1.59773 12.4091 1.5427 12.4984 1.50513C12.5877 1.46756 12.6836 1.4482 12.7804 1.4482C12.8773 1.4482 12.9732 1.46756 13.0625 1.50513C13.1518 1.5427 13.2327 1.59773 13.3004 1.667ZM5.81755 8.42991C5.817 8.3342 5.83535 8.23933 5.87153 8.15073C5.90772 8.06213 5.96104 7.98155 6.02844 7.9136L10.617 3.325L12.675 5.38296L8.0864 9.97156C8.01845 10.039 7.93787 10.0923 7.84927 10.1285C7.76067 10.1647 7.6658 10.183 7.57009 10.1824H6.54475C6.35189 10.1824 6.16692 10.1058 6.03055 9.96945C5.89417 9.83308 5.81755 9.64811 5.81755 9.45525V8.42991Z"
                      fill="#EC1C25"
                    />
                  </svg>
                  Edit Address
                </span>
              </p>
              <p>#78 House Street Cargo Bar</p>
              <p>Chandigarh</p>
              <p>India</p>
              <p>134203</p>
              <p>Angola</p>
            </div>
          </div>
          <div className="mt-4">
          <div className="col-md-12 belling-cart-address">
            <p><b>Add New Delivery Address?
            </b></p>
            <div className="row">
           <div className="col-md-8"> <p><span style={{color:"red"}}>Notes:</span> To update your default delivery address, please edit an address and then use the checkbox provided to mark it as default. 
           </p></div>
           <div className="col-md-4"><button className="update-account mt-0" style={{borderRadius:"100px"}}>Add new address</button></div>
            
            </div>
            
          </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default AccountInformation;
