import React from "react";
import Header from "../Header/Index";
import AmazingDeals from "./AmazingDeals";

import PlumbingCategories from "./PlumbingCategories";
import BathroomAccessoriesCard from "./BathroomAccessoriesCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FeaturedProductSecond from "./FeaturedProductSecond";
import AccordionSection from "./AccordionSection";
import ImageMan from "../../Images/youthful_cheerful_repairman_alone_on_transparent_background_g_1_3_1 1.png";
import Benefitsforyou from './Benefitsforyou';
import Footer from '../Footer/Index';
import HeatingTabs from './HeatingTabs';
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="" style={{ background: "#F4F4F4" }}>
        <div className="home-section pb-5">
          <Header />

          <div className="container pb-5">
            <AmazingDeals />
          </div>
        </div>

        {/* section two  */}
        <section>
          <div className="container mt-5 pb-5" id="heating">
            <HeatingTabs />
            
          </div>
        </section>

        {/* section three  */}
        <section>
          <div className="container-fluid mt-5 pb-5" id="plumbing-categories">
            <div className="featured-this-week">
              <div className="container pt-5">
                <p className="pt-5">Featured this week</p>
                <h2 className="mb-5">Plumbing Categories</h2>

                <Link to="/shop-catergory" className="mt-5">
                  <button className="get_a_quote_btn1 mt-2" type="submit">
                    See all collection{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ float: "right" }}
                      className="mt-1"
                    />
                  </button>
                </Link>
              </div>
            </div>
            <div className="container">
              <div className="featured-this-week-card">
                <PlumbingCategories />
              </div>
            </div>
          </div>
        </section>

        {/* section four  */}
        <section>
          <div className="container-fluid mt-5 pb-5" id="bathroom">
            <div className="bathroom-accessories pt-5">
              <div className="container pt-5">
                <h2 className="mb-5" >Bathroom Accessories</h2>

                <Link to="/shop-catergory" className="mt-5">
                  <button className="get_a_quote_btn1 mt-2" type="submit">
                    See all collection{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ float: "right" }}
                      className="mt-1"
                    />
                  </button>
                </Link>
              </div>
            </div>
            <div className="container">
              <div className="bathroom-accessories-card">
                <BathroomAccessoriesCard />
              </div>
            </div>
          </div>
        </section>

        {/* section five  */}

        <section>
          <div className="container-fluid pb-5 ">
            <div className="featured-this-week-second">
              <div className="container ">
                <p className="pt-5">Featured this week</p>
                <h2 className="mb-5">Featured Product</h2>
                <FeaturedProductSecond />
              </div>
            </div>
          </div>
        </section>

        {/* section six  */}

        <section>
          <div className="container pb-5" id="help">
            <div className="row mt-5 ">
              <div className="col-lg-6 help-section-left pt-5 ps-4 pb-5 pt-5">
                <h5>WE'RE HERE TO HELP</h5>
                <h2>Frequently Asked Questions</h2>
                <AccordionSection />
              </div>
              <div className="col-lg-6 help-section-right pt-5">
                <img src={ImageMan} alt="" />
              </div>
            </div>
          </div>
        </section>

      {/* section seven   */}

      <section>
       <Benefitsforyou />
      </section>
      
      <Footer />

      </div>
    </>
  );
};

export default Home;
