import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import logo from '../../Images/logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge2 from './Badge';
import MenuItem from '@mui/material/MenuItem';


const pages = [
  { label: 'Home', path: '/' },
  { label: 'Heating', path: '#heating' },
  { label: 'Plumbing', path: '#plumbing-categories' },
  { label: 'Bathrooms', path: '#bathroom' }, // Anchor link
  { label: 'About Us', path: '' },
  { label: 'Latest News', path: '' },
  { label: 'Profile', path: '/account' }
];

function Index() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = (e, path) => {
    if (path.startsWith('#')) {
      e.preventDefault();
      const targetId = path.substring(1);
      const targetElement = document.getElementById(targetId);
      
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      handleCloseNavMenu();
    }
  };

  return (
    <div className='p-0 position-relative'>
      <div className='everything-header'>
        <p className='p-2'>20% OFF EVERYTHING - USE CODE FLASH20 - EXPIRES TOMORROW</p>
      </div>
      <div className="everything-header-mail">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <FontAwesomeIcon icon={faPhoneVolume} /> 0118 958 8882
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3">
              <FontAwesomeIcon icon={faEnvelope} /> online@getplumb.co.uk
            </div>
          </div>
        </div>
      </div>

      <AppBar position="static custom-header-compare">
        <div className='container'>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={NavLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 800,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
              style={{ color: 'black' }}
            >
              <img src={logo} alt="" className='logo-nav p-2 py-3 mt-3' />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.label} onClick={(e) => handleScroll(e, page.path)}>
                    <NavLink
                      to={page.path.startsWith('#') ? location.pathname : page.path}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {page.label}
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="ps-5">
              {pages.map((page) => (
                <Button
                  key={page.label}
                  component={NavLink}
                  to={page.path.startsWith('#') ? location.pathname : page.path}
                  onClick={(e) => handleScroll(e, page.path)}
                  sx={{ my: 2, color: 'black', display: 'block', borderBottom: location.pathname === page.path ? '2px solid #EC1C25' : 'none' }}
                  className='nav-typography'
                >
                  {page.label}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </div>
      </AppBar>

      <div className="container">
        <div className="row">
          <div className="col-lg-9 mt-3">
            <div className="custom-search-bar-header d-flex">
              <div>
                <select className="form-select form-select-lg">
                  <option selected>Category</option>
                  <option value="" to="/shop-catergory">Shop Catergory</option>
                  <option value="">One</option>
                  <option value="">One</option>
                  <option value="">One</option>
                </select>
              </div>
              <input type="search" placeholder='Search here ...' />
            </div>
          </div>
          <div className="col-md-3 mt-3 d-flex">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className='custom-search-bar-dropdown'>
                Collection
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/">Shop Catergory</Dropdown.Item>
                <Dropdown.Item href="/">Product Details</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="badge ms-3">
              <Badge2 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
